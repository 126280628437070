var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-container container md:p-30px px-15px" },
    [
      _c("div", { staticClass: "container__header md:flex-row" }, [
        _c("div", {
          staticClass: "container__header__block container__header__block--left"
        }),
        _vm._v(" "),
        _c("div", { staticClass: "container__header__block" }, [
          _c("div", [
            _c("h1", { staticClass: "md:text-lg text-sm text-black01" }, [
              _vm._v(_vm._s("案件指示書"))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "container__header__block container__header__block--right md:mt-0 mt-15px md:justify-end ml-auto"
          },
          [
            _c("div", { staticClass: "header-buttons" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button leading-none py-5px px-15px w-fit md:min-w-auto min-w-100px bg-black01 md:w-full m-0 md: text-xs",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.store(true)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s("登録") + "\n        ")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container__content" }, [
        _c("div", [
          _c("div", { staticClass: "form project-form" }, [
            _c("div", { staticClass: "project-form-upper md:flex" }, [
              _c("div", { staticClass: "project-form-upper-left" }, [
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("案件ID"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-field-content" })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("作成日時"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-field-content" })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "project_status" } }, [
                    _vm._v(_vm._s("ステータス"))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-field-content" })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("バージョン"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-field-content" })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("ガント対象"))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("div", { staticClass: "radio_buttons flex" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.gantt,
                              expression: "project.gantt"
                            }
                          ],
                          staticClass: "w-1/2",
                          attrs: {
                            type: "radio",
                            id: "gantt_on",
                            name: "gantt"
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.project.gantt, true)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.project, "gantt", true)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "w-fit border-none projects_radio",
                            attrs: { for: "gantt_on" }
                          },
                          [_vm._v(_vm._s("あり"))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.gantt,
                              expression: "project.gantt"
                            }
                          ],
                          staticClass: "w-1/2",
                          attrs: {
                            type: "radio",
                            id: "gantt_off",
                            name: "gantt"
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.project.gantt, false)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.project, "gantt", false)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "w-fit border-none projects_radio",
                            attrs: { for: "gantt_off" }
                          },
                          [_vm._v(_vm._s("なし"))]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "message message--error message--input",
                      class: {
                        hidden: _vm.errors.gantt.length == 0
                      },
                      domProps: {
                        textContent: _vm._s(_vm.errors.gantt.join("\n"))
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "project_type" } }, [
                    _vm._v(_vm._s("ヘビー度"))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper form-field-content text-xs" },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "priority",
                          options: _vm.project_priorities,
                          reduce: function(x) {
                            return x.value
                          }
                        },
                        model: {
                          value: _vm.project.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "priority", $$v)
                          },
                          expression: "project.priority"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: { hidden: _vm.errors.priority.length == 0 },
                        domProps: {
                          textContent: _vm._s(_vm.errors.priority.join("\n"))
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("サンプル用"))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("div", { staticClass: "radio_buttons flex" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.sample,
                              expression: "project.sample"
                            }
                          ],
                          staticClass: "w-1/2",
                          attrs: {
                            type: "radio",
                            id: "sample_on",
                            name: "sample"
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.project.sample, true)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.project, "sample", true)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "w-fit border-none projects_radio",
                            attrs: { for: "sample_on" }
                          },
                          [_vm._v(_vm._s("ON"))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.sample,
                              expression: "project.sample"
                            }
                          ],
                          staticClass: "w-1/2",
                          attrs: {
                            type: "radio",
                            id: "sample_off",
                            name: "sample"
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.project.sample, false)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.project, "sample", false)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "w-fit border-none projects_radio",
                            attrs: { for: "sample_off" }
                          },
                          [_vm._v(_vm._s("OFF"))]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "message message--error message--input",
                      class: {
                        hidden: _vm.errors.sample.length == 0
                      },
                      domProps: {
                        textContent: _vm._s(_vm.errors.sample.join("\n"))
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "project-form-upper-right" }, [
                !_vm.isUserAffiliate
                  ? _c("div", { staticClass: "form-field" }, [
                      _c("label", [_vm._v(_vm._s("請求ID"))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "form-field-content" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-field w-full" }, [
                  _c("label", { attrs: { for: "project_manager" } }, [
                    _vm._v(_vm._s("作成者"))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "input-wrapper form-field-content flex-grow"
                    },
                    [
                      _c("v-select", {
                        staticClass: "block w-full min-w-0 max-w-full",
                        attrs: {
                          id: "project_manager",
                          options: _vm.selectUserWithAffiliateOptions,
                          reduce: function(x) {
                            return x.value
                          }
                        },
                        model: {
                          value: _vm.project.manager_id,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "manager_id", $$v)
                          },
                          expression: "project.manager_id"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: {
                          hidden: _vm.errors.manager_id.length == 0
                        },
                        domProps: {
                          textContent: _vm._s(_vm.errors.manager_id.join("\n"))
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                !_vm.isUserAffiliate
                  ? _c("div", { staticClass: "form-field" }, [
                      _c("label", { attrs: { for: "project_customer" } }, [
                        _vm._v(_vm._s("クライアント"))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-wrapper form-field-content flex-grow"
                        },
                        [
                          _c("v-select", {
                            staticClass: "block w-full min-w-0 max-w-full",
                            attrs: {
                              id: "project_customer",
                              options: _vm.selectCustomerOptions,
                              reduce: function(x) {
                                return x.value
                              }
                            },
                            model: {
                              value: _vm.project.customer_id,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "customer_id", $$v)
                              },
                              expression: "project.customer_id"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "message message--error message--input",
                            class: {
                              hidden: _vm.errors.customer_id.length == 0
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.customer_id.join("\n")
                              )
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isUserAffiliate
                  ? _c("div", { staticClass: "form-field" }, [
                      _c("label", { attrs: { for: "project_contact" } }, [
                        _vm._v(_vm._s("担当者名"))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-wrapper form-field-content flex-grow"
                        },
                        [
                          _c("v-select", {
                            staticClass: "block w-full min-w-0 max-w-full",
                            attrs: {
                              id: "project_contact",
                              options: _vm.selectContactOptions,
                              reduce: function(x) {
                                return x.value
                              }
                            },
                            model: {
                              value: _vm.project.contact_id,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "contact_id", $$v)
                              },
                              expression: "project.contact_id"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "message message--error message--input",
                            class: {
                              hidden: _vm.errors.contact_id.length == 0
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.contact_id.join("\n")
                              )
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "project_type" } }, [
                    _vm._v(_vm._s("種別"))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper form-field-content" },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "project_type",
                          options: _vm.project_types,
                          reduce: function(x) {
                            return x.value
                          }
                        },
                        model: {
                          value: _vm.project.type,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "type", $$v)
                          },
                          expression: "project.type"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: { hidden: _vm.errors.type.length == 0 },
                        domProps: {
                          textContent: _vm._s(_vm.errors.type.join("\n"))
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "project_start_date" } }, [
                    _vm._v(_vm._s("開始日時"))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("date-picker", {
                          attrs: {
                            format: "YYYY-MM-DD",
                            type: "date",
                            "value-type": "format"
                          },
                          model: {
                            value: _vm.project.start_date,
                            callback: function($$v) {
                              _vm.$set(_vm.project, "start_date", $$v)
                            },
                            expression: "project.start_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "message message--error message--input",
                      class: {
                        hidden: _vm.errors.start_date.length == 0
                      },
                      domProps: {
                        textContent: _vm._s(_vm.errors.start_date.join("\n"))
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "project_due_date" } }, [
                    _vm._v(_vm._s("期日時間"))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "project_due_date input-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("date-picker", {
                          staticClass: "w-3/5 flex-shrink-0 pr-10px",
                          attrs: {
                            format: "YYYY-MM-DD",
                            type: "date",
                            "value-type": "format"
                          },
                          model: {
                            value: _vm.project.due_date,
                            callback: function($$v) {
                              _vm.$set(_vm.project, "due_date", $$v)
                            },
                            expression: "project.due_date"
                          }
                        }),
                        _vm._v(" "),
                        _c("date-picker", {
                          staticClass: "w-2/5 flex-shrink-0",
                          class: { hidden: _vm.project.type != 0 },
                          attrs: {
                            format: "HH:mm",
                            type: "time",
                            "value-type": "format",
                            minuteStep: 30
                          },
                          model: {
                            value: _vm.project.due_time,
                            callback: function($$v) {
                              _vm.$set(_vm.project, "due_time", $$v)
                            },
                            expression: "project.due_time"
                          }
                        }),
                        _vm._v(" "),
                        _c("v-select", {
                          class: { hidden: _vm.project.type == 0 },
                          attrs: {
                            id: "project_due_time_period",
                            options: _vm.project_time_periods,
                            reduce: function(x) {
                              return x.value
                            }
                          },
                          model: {
                            value: _vm.project.due_time_period,
                            callback: function($$v) {
                              _vm.$set(_vm.project, "due_time_period", $$v)
                            },
                            expression: "project.due_time_period"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "message message--error message--input",
                      class: {
                        hidden:
                          _vm.errors.due_date.length == 0 &&
                          _vm.errors.due_time.length == 0 &&
                          _vm.errors.due_time_period.length == 0
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.errors.due_date
                            .concat(_vm.errors.due_time)
                            .concat(_vm.errors.due_time_period)
                            .join("\n")
                        )
                      }
                    })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "project-form-lower" }, [
              _c(
                "div",
                {
                  staticClass:
                    "form-field full-width mb-20px flex md:flex-row flex-col"
                },
                [
                  _c(
                    "label",
                    { staticClass: "mb-10px", attrs: { for: "project_name" } },
                    [_vm._v(_vm._s("案件名"))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper form-field-content" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.name,
                            expression: "project.name"
                          }
                        ],
                        staticClass: "project_name text-xs",
                        attrs: { type: "text", id: "project_name" },
                        domProps: { value: _vm.project.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.project, "name", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: { hidden: _vm.errors.name.length == 0 },
                        domProps: {
                          textContent: _vm._s(_vm.errors.name.join("\n"))
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", {}, [
                _c("div", { staticClass: "TODO flex flex-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                    },
                    [
                      _c("div", { staticClass: "TODO on-off flex-shrink-0" }, [
                        _c("div", { staticClass: "form-field" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-wrapper form-field-content w-fit"
                            },
                            [
                              _c("div", { staticClass: "radio_buttons" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex w-70px pb-8px" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.output_enabled,
                                          expression: "project.output_enabled"
                                        }
                                      ],
                                      staticClass: "w-1/2",
                                      attrs: {
                                        type: "radio",
                                        id: "output_enabled_on",
                                        name: "output_enabled"
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.project.output_enabled,
                                          true
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.project,
                                            "output_enabled",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "w-fit border-none projects_radio",
                                        attrs: { for: "output_enabled_on" }
                                      },
                                      [_vm._v(_vm._s("ON"))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex w-70px" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.project.output_enabled,
                                        expression: "project.output_enabled"
                                      }
                                    ],
                                    staticClass: "w-1/2",
                                    attrs: {
                                      type: "radio",
                                      id: "output_enabled_off",
                                      name: "output_enabled"
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.project.output_enabled,
                                        false
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.project,
                                          "output_enabled",
                                          false
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "w-fit border-none projects_radio",
                                      attrs: { for: "output_enabled_off" }
                                    },
                                    [_vm._v(_vm._s("OFF"))]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "message message--error message--input",
                                class: {
                                  hidden: _vm.errors.output_enabled.length == 0
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.output_enabled.join("\n")
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "TODO inputs w-full md:pr-0 pr-30px" },
                        [
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", { attrs: { for: "output_status" } }, [
                              _vm._v(_vm._s("出力ステータス"))
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "output_status",
                                    options: _vm.project_manager_statuses,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled: _vm.project.output_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.output_status,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "output_status",
                                        $$v
                                      )
                                    },
                                    expression: "project.output_status"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden: _vm.errors.output_status.length == 0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.output_status.join("\n")
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", { attrs: { for: "output_manager" } }, [
                              _vm._v(_vm._s("出力担当"))
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "output_manager",
                                    options: _vm.selectUserOutputDepartment,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled: _vm.project.output_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.output_manager_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "output_manager_id",
                                        $$v
                                      )
                                    },
                                    expression: "project.output_manager_id"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden:
                                      _vm.errors.output_manager_id.length == 0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.output_manager_id.join("\n")
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                    },
                    [
                      _c("div", { staticClass: "TODO on-off flex-shrink-0" }, [
                        _c("div", { staticClass: "form-field" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-wrapper form-field-content w-fit"
                            },
                            [
                              _c("div", { staticClass: "radio_buttons" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex w-70px pb-8px" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.technical_enabled,
                                          expression:
                                            "project.technical_enabled"
                                        }
                                      ],
                                      staticClass: "w-1/2",
                                      attrs: {
                                        type: "radio",
                                        id: "technical_enabled_on",
                                        name: "technical_enabled"
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.project.technical_enabled,
                                          true
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.project,
                                            "technical_enabled",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "w-fit border-none projects_radio",
                                        attrs: { for: "technical_enabled_on" }
                                      },
                                      [_vm._v(_vm._s("ON"))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex w-70px" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.project.technical_enabled,
                                        expression: "project.technical_enabled"
                                      }
                                    ],
                                    staticClass: "w-1/2",
                                    attrs: {
                                      type: "radio",
                                      id: "technical_enabled_off",
                                      name: "technical_enabled"
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.project.technical_enabled,
                                        false
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.project,
                                          "technical_enabled",
                                          false
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "w-fit border-none projects_radio",
                                      attrs: { for: "technical_enabled_off" }
                                    },
                                    [_vm._v(_vm._s("OFF"))]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "message message--error message--input",
                                class: {
                                  hidden:
                                    _vm.errors.technical_enabled.length == 0
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.technical_enabled.join("\n")
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "TODO inputs w-full md:pr-0 pr-30px" },
                        [
                          _c("div", { staticClass: "form-field" }, [
                            _c(
                              "label",
                              { attrs: { for: "technical_status" } },
                              [_vm._v(_vm._s("技術ステータス"))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "technical_status",
                                    options: _vm.project_manager_statuses,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled: _vm.project.technical_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.technical_status,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "technical_status",
                                        $$v
                                      )
                                    },
                                    expression: "project.technical_status"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden:
                                      _vm.errors.technical_status.length == 0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.technical_status.join("\n")
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c(
                              "label",
                              { attrs: { for: "techincal_manager" } },
                              [_vm._v(_vm._s("技術担当"))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "techincal_manager",
                                    options: _vm.selectUserTechnicalDepartment,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled: _vm.project.technical_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.technical_manager_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "technical_manager_id",
                                        $$v
                                      )
                                    },
                                    expression: "project.technical_manager_id"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden:
                                      _vm.errors.technical_manager_id.length ==
                                      0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.technical_manager_id.join("\n")
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                    },
                    [
                      _c("div", { staticClass: "TODO on-off flex-shrink-0" }, [
                        _c("div", { staticClass: "form-field" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-wrapper form-field-content w-fit"
                            },
                            [
                              _c("div", { staticClass: "radio_buttons" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex w-70px pb-8px" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.processing_enabled,
                                          expression:
                                            "project.processing_enabled"
                                        }
                                      ],
                                      staticClass: "w-1/2",
                                      attrs: {
                                        type: "radio",
                                        id: "processing_enabled_on",
                                        name: "processing_enabled"
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.project.processing_enabled,
                                          true
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.project,
                                            "processing_enabled",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "w-fit border-none projects_radio",
                                        attrs: { for: "processing_enabled_on" }
                                      },
                                      [_vm._v(_vm._s("ON"))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex w-70px" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.project.processing_enabled,
                                        expression: "project.processing_enabled"
                                      }
                                    ],
                                    staticClass: "w-1/2",
                                    attrs: {
                                      type: "radio",
                                      id: "processing_enabled_off",
                                      name: "processing_enabled"
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.project.processing_enabled,
                                        false
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.project,
                                          "processing_enabled",
                                          false
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "w-fit border-none projects_radio",
                                      attrs: { for: "processing_enabled_off" }
                                    },
                                    [_vm._v(_vm._s("OFF"))]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "message message--error message--input",
                                class: {
                                  hidden:
                                    _vm.errors.processing_enabled.length == 0
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.processing_enabled.join("\n")
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "TODO inputs w-full md:pr-0 pr-30px" },
                        [
                          _c("div", { staticClass: "form-field" }, [
                            _c(
                              "label",
                              { attrs: { for: "processing_status" } },
                              [_vm._v(_vm._s("加工ステータス"))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "processing_status",
                                    options: _vm.project_manager_statuses,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled:
                                      _vm.project.processing_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.processing_status,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "processing_status",
                                        $$v
                                      )
                                    },
                                    expression: "project.processing_status"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden:
                                      _vm.errors.processing_status.length == 0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.processing_status.join("\n")
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c(
                              "label",
                              { attrs: { for: "processing_manager" } },
                              [_vm._v(_vm._s("加工担当"))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-wrapper form-field-content flex-grow"
                              },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "block w-full min-w-0 max-w-full",
                                  attrs: {
                                    id: "processing_manager",
                                    options: _vm.selectUserProcessingDepartment,
                                    reduce: function(x) {
                                      return x.value
                                    },
                                    disabled:
                                      _vm.project.processing_enabled == 0
                                  },
                                  model: {
                                    value: _vm.project.processing_manager_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "processing_manager_id",
                                        $$v
                                      )
                                    },
                                    expression: "project.processing_manager_id"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "message message--error message--input",
                                  class: {
                                    hidden:
                                      _vm.errors.processing_manager_id.length ==
                                      0
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.processing_manager_id.join(
                                        "\n"
                                      )
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex full-width md:flex-row flex-col md:mb-8px mb-20px gap-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field full-width flex md:flex-row flex-col flex-1"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-10px flex flex-col"
                        },
                        [
                          _c("label", { attrs: { for: "stakeholder_info" } }, [
                            _vm._v(_vm._s("誰とつくる"))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v(
                              "クライアントや協力会社などの名前、どんな関係の人か"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-wrapper form-field-content mb-10px"
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.project.stakeholder_info,
                                expression: "project.stakeholder_info"
                              }
                            ],
                            staticClass: "py-8px pl-10px pr-15px",
                            staticStyle: {
                              "font-size": "12px",
                              "min-width": "0",
                              height: "5rem",
                              border: "1px solid",
                              "border-radius": "12px",
                              "line-height": "1",
                              "margin-bottom": "10px"
                            },
                            attrs: { type: "text", id: "stakeholder_info" },
                            domProps: { value: _vm.project.stakeholder_info },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.project,
                                    "stakeholder_info",
                                    $event.target.value
                                  )
                                },
                                _vm.handleStakeholderInfoUpdate
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "message message--error message--input",
                            class: {
                              hidden: _vm.errors.stakeholder_info.length == 0
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.stakeholder_info.join("\n")
                              )
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field full-width flex md:flex-row flex-col flex-1"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-10px flex flex-col"
                        },
                        [
                          _c("label", { attrs: { for: "event_info" } }, [
                            _vm._v(_vm._s("何をつくる"))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v(
                              "制作物の詳細や、置かれる場所・イベントについて"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-wrapper form-field-content mb-10px"
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.project.event_info,
                                expression: "project.event_info"
                              }
                            ],
                            staticClass: "py-8px pl-10px pr-15px",
                            staticStyle: {
                              "font-size": "12px",
                              "min-width": "0",
                              height: "5rem",
                              border: "1px solid",
                              "border-radius": "12px",
                              "line-height": "1",
                              "margin-bottom": "10px"
                            },
                            attrs: { type: "text", id: "event_info" },
                            domProps: { value: _vm.project.event_info },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.project,
                                    "event_info",
                                    $event.target.value
                                  )
                                },
                                _vm.handleEventInfoUpdate
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "message message--error message--input",
                            class: {
                              hidden: _vm.errors.event_info.length == 0
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.event_info.join("\n")
                              )
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field full-width flex md:flex-row flex-col flex-1"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-10px flex flex-col"
                        },
                        [
                          _c("label", { attrs: { for: "request_info" } }, [
                            _vm._v(_vm._s("期待されること"))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v("光伸に期待していること、依頼の経緯や理由")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-wrapper form-field-content mb-10px"
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.project.request_info,
                                expression: "project.request_info"
                              }
                            ],
                            staticClass: "py-8px pl-10px pr-15px",
                            staticStyle: {
                              "font-size": "12px",
                              "min-width": "0",
                              height: "5rem",
                              border: "1px solid",
                              "border-radius": "12px",
                              "line-height": "1",
                              "margin-bottom": "10px"
                            },
                            attrs: { type: "text", id: "request_info" },
                            domProps: { value: _vm.project.request_info },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.project,
                                    "request_info",
                                    $event.target.value
                                  )
                                },
                                _vm.handleRequestInfoUpdate
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "message message--error message--input",
                            class: {
                              hidden: _vm.errors.request_info.length == 0
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.request_info.join("\n")
                              )
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-field full-width flex md:flex-row flex-col"
                },
                [
                  _c(
                    "label",
                    { staticClass: "mb-10px", attrs: { for: "comment1" } },
                    [_vm._v(_vm._s("制作内容について"))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper form-field-content mb-30px" },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.comment1,
                            expression: "project.comment1"
                          }
                        ],
                        staticClass: "py-8px pl-10px pr-15px",
                        staticStyle: {
                          "font-size": "12px",
                          "min-width": "0",
                          height: "5rem",
                          border: "1px solid",
                          "border-radius": "12px",
                          "line-height": "1",
                          "margin-bottom": "10px"
                        },
                        attrs: { type: "text", id: "comment1" },
                        domProps: { value: _vm.project.comment1 },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.project,
                                "comment1",
                                $event.target.value
                              )
                            },
                            _vm.handleComment1Update
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: { hidden: _vm.errors.comment1.length == 0 },
                        domProps: {
                          textContent: _vm._s(_vm.errors.comment1.join("\n"))
                        }
                      }),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.comment2,
                            expression: "project.comment2"
                          }
                        ],
                        staticClass:
                          "text-xs text-red02 py-8px pl-10px pr-15px",
                        staticStyle: {
                          "min-width": "0",
                          height: "5rem",
                          border: "1px solid",
                          "border-radius": "12px",
                          "line-height": "1"
                        },
                        attrs: { type: "text", id: "comment2" },
                        domProps: { value: _vm.project.comment2 },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.project,
                                "comment2",
                                $event.target.value
                              )
                            },
                            _vm.handleComment2Update
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "message message--error message--input",
                        class: { hidden: _vm.errors.comment2.length == 0 },
                        domProps: {
                          textContent: _vm._s(_vm.errors.comment2.join("\n"))
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-field md:flex-row flex flex-col" },
                [
                  _c(
                    "label",
                    { staticClass: "mb-15px", attrs: { for: "files" } },
                    [_vm._v(_vm._s("添付PDF"))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "md:w-3/5" }, [
                    _c("div", [
                      _c("div", [
                        _c(
                          "ul",
                          _vm._l(_vm.files, function(file, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center w-full mb-15px"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col md:mx-10px mr-10px md:w-auto"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "pb-5px md:mx-auto border-none",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.moveFileUp(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "border-none",
                                            attrs: {
                                              src:
                                                "/images/svg/projects/file_move_up.svg",
                                              alt:
                                                "move " + file.filename + " up"
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "pt-5px md:mx-auto",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.moveFileDown(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/images/svg/projects/file_move_down.svg",
                                              alt:
                                                "move " +
                                                file.filename +
                                                " down"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "w-fit mr-15px" }, [
                                    _vm._v(_vm._s(index + 1))
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "block w-1/2 text-xs whitespace-no-wrap overflow-x-auto md:mr-50px mr-30px"
                                    },
                                    [_vm._v(_vm._s(file.filename))]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "mr-15px",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteFile(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          src:
                                            "/images/svg/projects/file_delete.svg",
                                          alt: "delete " + file.filename
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "border-none h-auto hidden",
                                    attrs: {
                                      type: "file",
                                      accept: "application/pdf",
                                      id: "replace-file-" + index,
                                      name: "replace-file-" + index
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.replaceFile($event, index)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "border-none cursor-pointer flex items-center w-auto",
                                      attrs: { for: "replace-file-" + index }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/images/svg/projects/file_replace.svg",
                                          alt: "replace " + file.filename
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "message message--error message--input",
                                class: {
                                  hidden: file.errors.length == 0
                                },
                                domProps: {
                                  textContent: _vm._s(file.errors.join("\n"))
                                }
                              })
                            ])
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "input-wrapper text-xs" }, [
                          _c("div", [
                            _c("input", {
                              staticClass: "border-none h-auto hidden",
                              attrs: {
                                type: "file",
                                accept: "application/pdf",
                                id: "file",
                                name: "file"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.uploadFile($event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "border-none cursor-pointer text-blue02",
                                attrs: { for: "file" }
                              },
                              [
                                _c("img", {
                                  staticClass: "inline pr-5px",
                                  attrs: {
                                    src:
                                      "/images/svg/projects/file_uploads.svg",
                                    alt: "追加アップロード"
                                  }
                                }),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s("追加アップロード") +
                                    "\n                      "
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container__footer mt-0" }, [
        _c("div", {
          staticClass: "container__footer__block container__footer__block--left"
        }),
        _vm._v(" "),
        _c("div", { staticClass: "container__footer__block" }, [
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "footer-buttons flex-row mx-auto" }, [
              _c(
                "button",
                {
                  staticClass: "button bg-black01 text-xs w-80px py-5px px-0",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.store()
                    }
                  }
                },
                [_vm._v("\n            " + _vm._s("登録") + "\n          ")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "container__footer__block container__footer__block--right"
        })
      ]),
      _vm._v(" "),
      _c("projects-modal-delete-pdf", {
        class: { hidden: _vm.deletePDFDialogData.hidden },
        attrs: { index: _vm.deletePDFDialogData.index },
        on: { close: _vm.deleteFileSubmit, submit: _vm.deleteFileSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }