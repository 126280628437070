var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container p-30px border-grey02 w-full" }, [
    _c(
      "div",
      {
        staticClass:
          "flex md:flex-row flex-col md:justify-between md:items-center mb-30px w-full"
      },
      [
        _c("div", { staticClass: "w-fit md:mb-0 mb-10px" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _c("img", {
              staticClass: "md:h-30px h-24px mr-10px",
              attrs: { src: "/images/svg/projects/icon-list.svg" }
            }),
            _vm._v(" "),
            _c(
              "h1",
              { staticClass: "text-black01 font-medium md:text-lg text-sm" },
              [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", {}),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container__content w-full overflow-x-auto" }, [
      _c(
        "table",
        { staticClass: "project-table md:whitespace-normal whitespace-nowrap" },
        [
          _c("thead", [
            _c(
              "tr",
              _vm._l(_vm.tableHeaders, function(header, index) {
                return _c(
                  "th",
                  { key: index },
                  [
                    !header.sortable
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(header.name) +
                              "\n            "
                          )
                        ]
                      : [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.changeSort(header.key)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(header.name) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-sort":
                                _vm.sortProperty != header.key ||
                                (_vm.sortDirection != "asc" &&
                                  _vm.sortDirection != "desc"),
                              "fa-sort-up":
                                _vm.sortProperty == header.key &&
                                _vm.sortDirection == "asc",
                              "fa-sort-down":
                                _vm.sortProperty == header.key &&
                                _vm.sortDirection == "desc"
                            }
                          })
                        ]
                  ],
                  2
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.files.data, function(file) {
              return _c("tr", { key: file.pdf }, [
                _c("td", { staticClass: "min-w-250px" }, [
                  _c("a", { attrs: { href: file.pdf_url, target: "_blank" } }, [
                    _c("img", {
                      staticClass:
                        "w-full max-w-250px max-h-250px object-contain",
                      attrs: { src: file.thumbnail_url, alt: file.thumbnail }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "min-w-500px" }, [
                  _vm._v(
                    "\n            " + _vm._s(file.pdf_name) + "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "99%" } }, [
                  _vm._v("\n            " + _vm._s(file.date) + "\n          ")
                ])
              ])
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container__footer" }, [
      _c("div", { staticClass: "container__footer__block" }, [
        _c("div", [
          _c(
            "div",
            [
              _c("pagination", {
                attrs: {
                  links: _vm.files.links,
                  totalPages: _vm.files.last_page
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md:self-auto self-end" }, [_c("div", {})])
  }
]
render._withStripped = true

export { render, staticRenderFns }