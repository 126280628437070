<template>
  <div class="container p-30px border-grey02 w-full">
    <div
      class="flex md:flex-row flex-col md:justify-between md:items-center mb-30px w-full"
    >
      <div class="w-fit md:mb-0 mb-10px">
        <div class="flex items-center">
          <img
            :src="'/images/svg/projects/icon-list.svg'"
            class="md:h-30px h-24px mr-10px"
          />
          <h1 class="text-black01 font-medium md:text-lg text-sm">
            {{ title }}
          </h1>
        </div>
      </div>
      <div class=""></div>
      <div class="md:self-auto self-end">
        <div class="">
          <template>
            <div class="flex">
              <button
                class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px"
                v-on:click="openSearchDialog"
              >
                {{ "過去データ検索" }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container__content w-full overflow-x-auto">
      <table class="project-table md:whitespace-normal whitespace-nowrap">
        <thead>
          <tr>
            <th v-for="(header, index) in tableHeaders" v-bind:key="index">
              <template v-if="!header.sortable">
                {{ header.name }}
              </template>
              <template v-else>
                <a href="#" v-on:click="changeSort(header.key)">
                  {{ header.name }}
                </a>
                <i
                  class="fa"
                  v-bind:class="{
                    'fa-sort':
                      sortProperty != header.key ||
                      (sortDirection != 'asc' && sortDirection != 'desc'),
                    'fa-sort-up':
                      sortProperty == header.key && sortDirection == 'asc',
                    'fa-sort-down':
                      sortProperty == header.key && sortDirection == 'desc'
                  }"
                ></i>
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="date in dates.data" v-bind:key="date.date">
            <td class="w-1/10 min-w-100px">
              <a
                class="opacity decoration"
                :href="buildListUrl(date.start, date.end)"
                >{{ date.date }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container__footer">
      <div class="container__footer__block">
        <div>
          <div>
            <pagination
              :links="dates.links"
              :totalPages="dates.last_page"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
    <search-dialog
      :dialog-data="searchDialog"
      v-on:close-dialog="closeSearchDialog"
      v-on:submit-search="submitSearch"
    ></search-dialog>
  </div>
</template>

<style scoped>
a {
  color: #323232;
}
</style>

<script>
import SearchDialog from "../../SearchDialog.vue";
export default {
  components: { SearchDialog },
  props: {
    dates: Object,
    pagination: Object,
    urls: Object
  },
  data() {
    return {
      title: "過去データ一覧",
      tableHeaders: [
        {
          sortable: true,
          key: "date",
          name: "年月"
        }
      ],
      sortProperty: null,
      sortDirection: null,
      searchDialog: {
        shown: false,
        title: "過去データ検索",
        submitLabel: "検索",
        fields: [
          {
            label: "最終更新日",
            type: "daterange",
            start: {
              property: "date_start",
              value: "",
              default: "",
              format: "YYYY-MM-DD",
              value_type: "format",
              type: "date",
              class: "full-width"
            },
            end: {
              property: "date_end",
              value: "",
              default: "",
              format: "YYYY-MM-DD",
              value_type: "format",
              type: "date",
              class: "full-width"
            }
          }
        ]
      }
    };
  },
  methods: {
    buildListUrl: function(start, end) {
      return this.urls.list
        .replace(/__DATE_START__/g, start)
        .replace(/__DATE_END__/g, end);
    },
    openSearchDialog: function() {
      this.searchDialog.shown = true;
    },
    closeSearchDialog: function() {
      this.searchDialog.shown = false;
    },
    submitSearch: function() {
      //this.closeSearchDialog();
      var params = new URLSearchParams(window.location.search);
      const date_start = this.searchDialog.fields[0].start.value;
      const date_end = this.searchDialog.fields[0].end.value;

      window.location = this.buildListUrl(date_start, date_end);
    },
    changeSort: function(sort_key) {
      var sortDirection = this.sortDirection;
      if (this.sortProperty != sort_key) {
        sortDirection = "null";
      }
      this.sortProperty = sort_key;
      switch (sortDirection) {
        case "null":
          this.sortDirection = "asc";
          break;
        case "asc":
          this.sortDirection = "desc";
          break;
        case "desc":
          this.sortProperty = null;
          this.sortDirection = null;
          break;
      }
      var params = new URLSearchParams(window.location.search);
      params.delete("page");
      if (this.sortProperty !== null) {
        params.set("sort", this.sortProperty);
        params.set("direction", this.sortDirection);
      } else {
        params.delete("sort");
        params.delete("direction");
      }
      window.location.search = params.toString();
    }
  },
  mounted() {
    var params = new URLSearchParams(window.location.search);
    if (params.has("sort")) {
      this.sortProperty = params.get("sort");
    }
    if (params.has("direction")) {
      this.sortDirection = params.get("direction");
    }
    this.searchDialog.fields.forEach(field => {
      if (field.start) {
        if (field.start.cast == "integer") {
          field.start.value = parseInt(params.get(field.start.property));
        } else {
          field.start.value = params.get(field.start.property);
        }
      }
      if (field.end) {
        if (field.end.cast == "integer") {
          field.end.value = parseInt(params.get(field.end.property));
        } else {
          field.end.value = params.get(field.end.property);
        }
      }
      if (field.property) {
        if (field.cast == "integer") {
          field.value = parseInt(params.get(field.property));
        } else {
          field.value = params.get(field.property);
        }
      }
    });
  }
};
</script>
