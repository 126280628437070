var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-container container md:p-30px px-15px border-grey02"
    },
    [
      _c("div", { staticClass: "container__header mb-30px flex md:flex-row" }, [
        _c("div", {
          staticClass: "container__header__block container__header__block--left"
        }),
        _vm._v(" "),
        _c(
          "h1",
          {
            staticClass:
              "container__header__block md:text-lg text-sm text-black01 text-center font-medium md:mb-0 mb-15px"
          },
          [_vm._v("\n      " + _vm._s("案件指示書") + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "container__header__block flex justify-end items-center"
          },
          [
            _vm.project.status != 3
              ? _c(
                  "button",
                  {
                    staticClass:
                      "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px mr-10px",
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { type: "button" },
                    on: { click: _vm.openDiscardModal }
                  },
                  [_vm._v("\n        " + _vm._s("廃棄する") + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px mr-10px",
                staticStyle: { "white-space": "nowrap" },
                attrs: { href: _vm.urls.duplicate }
              },
              [_vm._v("\n        " + _vm._s("コピーする") + "\n      ")]
            ),
            _vm._v(" "),
            _vm.project.status != 3
              ? _c(
                  "a",
                  {
                    staticClass:
                      "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px",
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { href: _vm.urls.edit }
                  },
                  [_vm._v("\n        " + _vm._s("編集") + "\n      ")]
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container__content" }, [
        _c("div", [
          _c("div", { staticClass: "form project-form" }, [
            _c("div", { staticClass: "project-form-upper md:flex" }, [
              _c("div", { staticClass: "project-form-upper-left" }, [
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("案件ID"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.project.friendly_id))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("作成日時"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatISODate(
                          _vm.project.created_at,
                          "yyyy-MM-dd HH:mm:ss"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("ステータス"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.project.status_name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("バージョン"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.project.version))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("ガント対象"))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper text-xs leading-loose" },
                    [
                      _c("div", { staticClass: "radio_buttons" }, [
                        _c(
                          "label",
                          { staticClass: "border-none text-blue00" },
                          [_vm._v(_vm._s(_vm.project.gantt ? "✔" : "　"))]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "border-none" }, [
                          _vm._v(_vm._s("あり"))
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "border-none text-blue00" },
                          [_vm._v(_vm._s(!_vm.project.gantt ? "✔" : "　"))]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "border-none" }, [
                          _vm._v(_vm._s("なし"))
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("ヘビー度"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.project.priority_name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("サンプル用"))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper text-xs leading-loose" },
                    [
                      _c("div", { staticClass: "radio_buttons" }, [
                        _c(
                          "label",
                          { staticClass: "border-none text-blue00" },
                          [_vm._v(_vm._s(_vm.project.sample ? "✔" : "　"))]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "border-none" }, [
                          _vm._v(_vm._s("ON"))
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "border-none text-blue00" },
                          [_vm._v(_vm._s(!_vm.project.sample ? "✔" : "　"))]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "border-none" }, [
                          _vm._v(_vm._s("OFF"))
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "project-form-upper-right" }, [
                !_vm.isUserAffiliate
                  ? _c("div", { staticClass: "form-field" }, [
                      _c("label", [_vm._v(_vm._s("請求ID"))]),
                      _vm._v(" "),
                      _vm.project.bill
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "text-xs leading-loose opacity decoration",
                              attrs: {
                                href: _vm.buildBillUrl(_vm.project.bill),
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.project.bill
                                    ? _vm.project.bill.friendly_id
                                    : ""
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("作成者"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.managerName))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("クライアント"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.customerName))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("担当者名"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.contactName))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("種別"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(_vm._s(_vm.project.type_name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("開始日時"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(
                      _vm._s(
                        _vm.project.start_date
                          ? _vm.formatISODate(
                              _vm.project.start_date,
                              "yoMMMdo EEEEE"
                            )
                          : ""
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", [_vm._v(_vm._s("期日時間"))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-xs leading-loose" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatISODate(_vm.project.due_date, "yoMMMdo EEEEE")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "text-xs leading-loose ml-5px",
                      class: { hidden: _vm.project.type != 0 }
                    },
                    [_vm._v(_vm._s(_vm.project.due_time))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "text-xs leading-loose ml-5px",
                      class: { hidden: _vm.project.type == 0 }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.project.due_time_period == 1 ? "PM" : "AM")
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "project-form-lower" }, [
              _c(
                "div",
                {
                  staticClass:
                    "form-field full-width md:flex-row flex-col mb-20px"
                },
                [
                  _c("label", [_vm._v(_vm._s("案件名"))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "form-field-content text-xs leading-loose project_name"
                    },
                    [_vm._v(_vm._s(_vm.project.name))]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-field" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "TODO flex? md:flex flex-wrap justify-between w-full"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "TODO flex item, vertical orientation md:w-1/2 mb-10px"
                      },
                      [
                        _c("div", { staticClass: "TODO inputs" }, [
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("出力ステータス"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: { hidden: !_vm.project.output_enabled }
                              },
                              [_vm._v(_vm._s(_vm.project.output_status_name))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("出力担当"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: { hidden: !_vm.project.output_enabled }
                              },
                              [_vm._v(_vm._s(_vm.outputManagerName))]
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "TODO flex item, vertical orientation md:w-1/2 mb-10px"
                      },
                      [
                        _c("div", { staticClass: "TODO inputs" }, [
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("技術ステータス"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: {
                                  hidden: !_vm.project.technical_enabled
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.project.technical_status_name)
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("技術担当"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: {
                                  hidden: !_vm.project.technical_enabled
                                }
                              },
                              [_vm._v(_vm._s(_vm.technicalManagerName))]
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "TODO flex item, vertical orientation md:w-1/2 mb-10px"
                      },
                      [
                        _c("div", { staticClass: "TODO inputs" }, [
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("加工ステータス"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: {
                                  hidden: !_vm.project.processing_enabled
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.project.processing_status_name)
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-field" }, [
                            _c("label", [_vm._v(_vm._s("加工担当"))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "text-xs leading-loose",
                                class: {
                                  hidden: !_vm.project.processing_enabled
                                }
                              },
                              [_vm._v(_vm._s(_vm.processingManagerName))]
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex full-width md:flex-row flex-col md:mb-8px mb-20px gap-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field md:flex-row flex-col flex-1 mb-10px"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-15px flex flex-col"
                        },
                        [
                          _c("label", [_vm._v(_vm._s("誰とつくる"))]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v(
                              "クライアントや協力会社などの名前、どんな関係の人か"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "text-xs leading-loose info-block",
                        domProps: {
                          textContent: _vm._s(_vm.project.stakeholder_info)
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field md:flex-row flex-col flex-1 mb-10px"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-15px flex flex-col"
                        },
                        [
                          _c("label", [_vm._v(_vm._s("何をつくる"))]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v(
                              "制作物の詳細や、置かれる場所・イベントについて"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "text-xs leading-loose info-block",
                        domProps: {
                          textContent: _vm._s(_vm.project.event_info)
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field md:flex-row flex-col flex-1 mb-10px"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label-container mb-15px flex flex-col"
                        },
                        [
                          _c("label", [_vm._v(_vm._s("期待されること"))]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v("光伸に期待していること、依頼の経緯や理由")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "text-xs leading-loose info-block",
                        domProps: {
                          textContent: _vm._s(_vm.project.request_info)
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "form-field full-width md:flex-row flex-col mb-30px"
                },
                [
                  _c("label", { staticClass: "mb-15px" }, [
                    _vm._v(_vm._s("制作内容について"))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-4/5" }, [
                    _c("div", {
                      staticClass:
                        "text-xs leading-loose w-full mb-10px project-comment",
                      domProps: { textContent: _vm._s(_vm.project.comment1) }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "text-xs leading-loose w-full text-red02 project-comment",
                      domProps: { textContent: _vm._s(_vm.project.comment2) }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-field" }, [
                _c("label", [_vm._v(_vm._s("指示PDF"))]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.urls.instructions_pdf,
                        download:
                          _vm.formatDate(_vm.project.updated_at) +
                          "-" +
                          _vm.project.friendly_id +
                          ".pdf"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/images/svg/projects/file_download.svg",
                          alt: "instructions pdf download"
                        }
                      })
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-field md:flex-row flex flex-col mb-30px" },
                [
                  _c("label", { staticClass: "mb-15px" }, [
                    _vm._v(_vm._s("添付PDF"))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "md:w-3/5" }, [
                    _c("div", [
                      _c("div", [
                        _c(
                          "ul",
                          _vm._l(_vm.project.project_files, function(
                            file,
                            index
                          ) {
                            return _c("li", { key: index }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center w-full mb-15px"
                                },
                                [
                                  _c("span", { staticClass: "w-fit mr-15px" }, [
                                    _vm._v(_vm._s(index + 1))
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "block w-1/2 text-xs whitespace-no-wrap overflow-x-auto md:mr-50px mr-30px"
                                    },
                                    [_vm._v(_vm._s(file.filename))]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "mr-15px",
                                      attrs: {
                                        href: file.urls.download,
                                        download:
                                          _vm.formatDate(file.updated_at) +
                                          "-" +
                                          file.filename
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          src:
                                            "/images/svg/projects/file_download.svg",
                                          alt: "download " + file.filename
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mobile-disabled print-button",
                                      class: { disabled: !_vm.printingEnabled },
                                      attrs: { href: "#null" },
                                      on: {
                                        click: function($event) {
                                          return _vm.print(file.urls.print)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          src:
                                            "/images/svg/projects/file_print_small.svg",
                                          alt: "print " + file.filename
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "a",
                            {
                              staticClass: "mr-20px",
                              attrs: {
                                href: _vm.urls.download,
                                download:
                                  _vm.formatISODate(
                                    _vm.project.due_date,
                                    "MMdd"
                                  ) +
                                  "_" +
                                  _vm.project.name +
                                  ".pdf"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "mx-auto",
                                attrs: {
                                  src:
                                    "/images/svg/projects/file_pdf_download.svg",
                                  alt: "download all pdfs"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-3xs" }, [
                                _vm._v(_vm._s("全てダウンロード"))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "mobile-disable print-button",
                              class: { disabled: !_vm.printingEnabled },
                              attrs: { href: "#null" },
                              on: {
                                click: function($event) {
                                  return _vm.print(_vm.urls.print)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/images/svg/projects/file_print.svg",
                                  alt: "print all pdfs"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-3xs" }, [
                                _vm._v(_vm._s("全て印刷"))
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-field md:flex-row flex-col" }, [
                _c("label", { staticClass: "mb-15px" }, [
                  _vm._v(_vm._s("変更履歴"))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.project.project_updates, function(update) {
                    return _c(
                      "div",
                      { key: update.id, staticClass: "flex flex-wrap mb-15px" },
                      [
                        _c("div", { staticClass: "pr-10px mb-5px" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.formatISODate(
                                  update.created_at,
                                  "yyyy-MM-dd HH:mm:ss"
                                )
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.updateCreatedByDepartment(update.created_by)
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pl-10px" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.updateCreatedByName(update.created_by)
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "w-full p-5px bg-grey00",
                            staticStyle: { "border-radius": "10px" }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(update.comment) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("projects-modal-discard", {
        class: { hidden: _vm.discardModalHidden },
        attrs: { url: _vm.urls.delete },
        on: { close: _vm.closeDiscardModal }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container__footer" }, [
      _c("div", {
        staticClass: "container__footer__block container__footer__block--left"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container__footer__block" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "container__footer__block container__footer__block--right"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }