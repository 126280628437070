<template>
  <div class="container p-30px border-grey02 w-full">
    <div
      class="flex md:flex-row flex-col md:justify-between md:items-center mb-30px w-full"
    >
      <div class="w-fit md:mb-0 mb-10px">
        <div class="flex items-center">
          <img
            :src="'/images/svg/projects/icon-list.svg'"
            class="md:h-30px h-24px mr-10px"
          />
          <h1 class="text-black01 font-medium md:text-lg text-sm">
            {{ title }}
          </h1>
        </div>
      </div>
      <div class=""></div>
      <div class="md:self-auto self-end">
        <div class=""></div>
      </div>
    </div>
    <div class="container__content w-full overflow-x-auto">
      <table class="project-table md:whitespace-normal whitespace-nowrap">
        <thead>
          <tr>
            <th v-for="(header, index) in tableHeaders" v-bind:key="index">
              <template v-if="!header.sortable">
                {{ header.name }}
              </template>
              <template v-else>
                <a href="#" v-on:click="changeSort(header.key)">
                  {{ header.name }}
                </a>
                <i
                  class="fa"
                  v-bind:class="{
                    'fa-sort':
                      sortProperty != header.key ||
                      (sortDirection != 'asc' && sortDirection != 'desc'),
                    'fa-sort-up':
                      sortProperty == header.key && sortDirection == 'asc',
                    'fa-sort-down':
                      sortProperty == header.key && sortDirection == 'desc'
                  }"
                ></i>
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files.data" v-bind:key="file.pdf">
            <td class="min-w-250px">
              <a class="" :href="file.pdf_url" target="_blank">
                <img
                  :src="file.thumbnail_url"
                  :alt="file.thumbnail"
                  class="w-full max-w-250px max-h-250px object-contain"
                />
              </a>
            </td>
            <td class="min-w-500px">
              {{ file.pdf_name }}
            </td>
            <td class="" width="99%">
              {{ file.date }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container__footer">
      <div class="container__footer__block">
        <div>
          <div>
            <pagination
              :links="files.links"
              :totalPages="files.last_page"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
a {
  color: #323232;
}
</style>

<script>
export default {
  props: {
    files: Object,
    pagination: Object,
    urls: Object
  },
  data() {
    return {
      title: "過去データ一覧",
      tableHeaders: [
        {
          sortable: true,
          key: "thumbnail",
          name: "サムネイル"
        },
        {
          sortable: true,
          key: "pdf",
          name: "ファイル名"
        },
        {
          sortable: true,
          key: "date",
          name: "最終更新日"
        }
      ],
      sortProperty: null,
      sortDirection: null
    };
  },
  methods: {
    changeSort: function(sort_key) {
      var sortDirection = this.sortDirection;
      if (this.sortProperty != sort_key) {
        sortDirection = "null";
      }
      this.sortProperty = sort_key;
      switch (sortDirection) {
        case "null":
          this.sortDirection = "asc";
          break;
        case "asc":
          this.sortDirection = "desc";
          break;
        case "desc":
          this.sortProperty = null;
          this.sortDirection = null;
          break;
      }
      var params = new URLSearchParams(window.location.search);
      params.delete("page");
      if (this.sortProperty !== null) {
        params.set("sort", this.sortProperty);
        params.set("direction", this.sortDirection);
      } else {
        params.delete("sort");
        params.delete("direction");
      }
      window.location.search = params.toString();
    }
  },
  mounted() {
    var params = new URLSearchParams(window.location.search);
    if (params.has("sort")) {
      this.sortProperty = params.get("sort");
    }
    if (params.has("direction")) {
      this.sortDirection = params.get("direction");
    }
  }
};
</script>
