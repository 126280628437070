<template>
  <div class="project-container container md:p-30px px-15px border-grey02">
    <div class="container__header mb-30px flex md:flex-row">
      <div
        class="container__header__block container__header__block--left"
      ></div>
      <h1
        class="container__header__block md:text-lg text-sm text-black01 text-center font-medium md:mb-0 mb-15px"
      >
        {{ "案件指示書" }}
      </h1>
      <div class="container__header__block flex justify-end items-center">
        <button
          v-if="project.status != 3"
          v-on:click="openDiscardModal"
          class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px mr-10px"
          style="white-space: nowrap"
          type="button"
        >
          {{ "廃棄する" }}
        </button>
        <a
          v-bind:href="urls.duplicate"
          class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px mr-10px"
          style="white-space: nowrap"
        >
          {{ "コピーする" }}
        </a>
        <a
          v-if="project.status != 3"
          v-bind:href="urls.edit"
          class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px"
          style="white-space: nowrap"
        >
          {{ "編集" }}
        </a>
      </div>
    </div>
    <div class="container__content">
      <div>
        <div class="form project-form">
          <div class="project-form-upper md:flex">
            <div class="project-form-upper-left">
              <div class="form-field">
                <label>{{ "案件ID" }}</label>
                <span class="text-xs leading-loose">{{
                  project.friendly_id
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "作成日時" }}</label>
                <span class="text-xs leading-loose">{{
                  formatISODate(project.created_at, "yyyy-MM-dd HH:mm:ss")
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "ステータス" }}</label>
                <span class="text-xs leading-loose">{{
                  project.status_name
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "バージョン" }}</label>
                <span class="text-xs leading-loose">{{ project.version }}</span>
              </div>
              <div class="form-field">
                <label>{{ "ガント対象" }}</label>
                <div class="input-wrapper text-xs leading-loose">
                  <div class="radio_buttons">
                    <label class="border-none text-blue00">{{
                      project.gantt ? "&#10004;" : "　"
                    }}</label>
                    <label class="border-none">{{ "あり" }}</label>
                    <label class="border-none text-blue00">{{
                      !project.gantt ? "&#10004;" : "　"
                    }}</label>
                    <label class="border-none">{{ "なし" }}</label>
                  </div>
                </div>
              </div>
              <div class="form-field">
                <label>{{ "ヘビー度" }}</label>
                <span class="text-xs leading-loose">{{
                  project.priority_name
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "サンプル用" }}</label>
                <div class="input-wrapper text-xs leading-loose">
                  <div class="radio_buttons">
                    <label class="border-none text-blue00">{{
                      project.sample ? "&#10004;" : "　"
                    }}</label>
                    <label class="border-none">{{ "ON" }}</label>
                    <label class="border-none text-blue00">{{
                      !project.sample ? "&#10004;" : "　"
                    }}</label>
                    <label class="border-none">{{ "OFF" }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="project-form-upper-right">
              <div v-if="!isUserAffiliate" class="form-field">
                <label>{{ "請求ID" }}</label>
                <a
                  v-if="project.bill"
                  :href="buildBillUrl(project.bill)"
                  target="_blank"
                  class="text-xs leading-loose opacity decoration"
                  >{{ project.bill ? project.bill.friendly_id : "" }}</a
                >
              </div>
              <div class="form-field">
                <label>{{ "作成者" }}</label>
                <span class="text-xs leading-loose">{{ managerName }}</span>
              </div>
              <div class="form-field">
                <label>{{ "クライアント" }}</label>
                <span class="text-xs leading-loose">{{ customerName }}</span>
              </div>
              <div class="form-field">
                <label>{{ "担当者名" }}</label>
                <span class="text-xs leading-loose">{{ contactName }}</span>
              </div>
              <div class="form-field">
                <label>{{ "種別" }}</label>
                <span class="text-xs leading-loose">{{
                  project.type_name
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "開始日時" }}</label>
                <span class="text-xs leading-loose">{{
                  project.start_date
                    ? formatISODate(project.start_date, "yoMMMdo EEEEE")
                    : ""
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "期日時間" }}</label>
                <span class="text-xs leading-loose">{{
                  formatISODate(project.due_date, "yoMMMdo EEEEE")
                }}</span>

                <span
                  v-bind:class="{ hidden: project.type != 0 }"
                  class="text-xs leading-loose ml-5px"
                  >{{ project.due_time }}</span
                >

                <span
                  v-bind:class="{ hidden: project.type == 0 }"
                  class="text-xs leading-loose ml-5px"
                  >{{ project.due_time_period == 1 ? "PM" : "AM" }}</span
                >
              </div>
            </div>
          </div>
          <div class="project-form-lower">
            <div class="form-field full-width md:flex-row flex-col mb-20px">
              <label>{{ "案件名" }}</label>
              <span
                class="form-field-content text-xs leading-loose project_name"
                >{{ project.name }}</span
              >
            </div>
            <div class="form-field">
              <div class="TODO flex? md:flex flex-wrap justify-between w-full">
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 mb-10px"
                >
                  <div class="TODO inputs">
                    <div class="form-field">
                      <label>{{ "出力ステータス" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.output_enabled }"
                        >{{ project.output_status_name }}</span
                      >
                    </div>
                    <div class="form-field">
                      <label>{{ "出力担当" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.output_enabled }"
                        >{{ outputManagerName }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 mb-10px"
                >
                  <div class="TODO inputs">
                    <div class="form-field">
                      <label>{{ "技術ステータス" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.technical_enabled }"
                        >{{ project.technical_status_name }}</span
                      >
                    </div>
                    <div class="form-field">
                      <label>{{ "技術担当" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.technical_enabled }"
                        >{{ technicalManagerName }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 mb-10px"
                >
                  <div class="TODO inputs">
                    <div class="form-field">
                      <label>{{ "加工ステータス" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.processing_enabled }"
                        >{{ project.processing_status_name }}</span
                      >
                    </div>
                    <div class="form-field">
                      <label>{{ "加工担当" }}</label>
                      <span
                        class="text-xs leading-loose"
                        :class="{ hidden: !project.processing_enabled }"
                        >{{ processingManagerName }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex full-width md:flex-row flex-col md:mb-8px mb-20px gap-2"
            >
              <div class="form-field md:flex-row flex-col flex-1 mb-10px">
                <div class="label-container mb-15px flex flex-col">
                  <label>{{ "誰とつくる" }}</label>
                  <span class="text-xs">クライアントや協力会社などの名前、どんな関係の人か</span>
                </div>
                <span
                  class="text-xs leading-loose info-block"
                  v-text="project.stakeholder_info"
                ></span>
              </div>
              <div class="form-field md:flex-row flex-col flex-1 mb-10px">
                <div class="label-container mb-15px flex flex-col">
                  <label>{{ "何をつくる" }}</label>
                  <span class="text-xs">制作物の詳細や、置かれる場所・イベントについて</span>
                </div>
                <span
                  class="text-xs leading-loose info-block"
                  v-text="project.event_info"
                ></span>
              </div>
              <div class="form-field md:flex-row flex-col flex-1 mb-10px">
                <div class="label-container mb-15px flex flex-col">
                  <label>{{ "期待されること" }}</label>
                  <span class="text-xs">光伸に期待していること、依頼の経緯や理由</span>
                </div>
                <span
                  class="text-xs leading-loose info-block"
                  v-text="project.request_info"
                ></span>
              </div>
            </div>

            <div class="form-field full-width md:flex-row flex-col mb-30px">
              <label class="mb-15px">{{ "制作内容について" }}</label>
              <div class="w-4/5">
                <div
                  class="text-xs leading-loose w-full mb-10px project-comment"
                  v-text="project.comment1"
                ></div>
                <div
                  class="text-xs leading-loose w-full text-red02 project-comment"
                  v-text="project.comment2"
                ></div>
              </div>
            </div>
            <div class="form-field">
              <label>{{ "指示PDF" }}</label>
              <div>
                <a
                  :href="urls.instructions_pdf"
                  :download="
                    `${formatDate(project.updated_at)}-${
                      project.friendly_id
                    }.pdf`
                  "
                >
                  <img
                    src="/images/svg/projects/file_download.svg"
                    alt="instructions pdf download"
                  />
                </a>
              </div>
            </div>
            <div class="form-field md:flex-row flex flex-col mb-30px">
              <label class="mb-15px">{{ "添付PDF" }}</label>
              <div class="md:w-3/5">
                <div>
                  <div>
                    <ul>
                      <li
                        v-for="(file, index) in project.project_files"
                        :key="index"
                      >
                        <div class="flex items-center w-full mb-15px">
                          <span class="w-fit mr-15px">{{ index + 1 }}</span>
                          <br />
                          <span
                            class="block w-1/2 text-xs whitespace-no-wrap overflow-x-auto md:mr-50px mr-30px"
                            >{{ file.filename }}</span
                          >
                          <br />
                          <a
                            :href="file.urls.download"
                            :download="
                              `${formatDate(file.updated_at)}-${file.filename}`
                            "
                            class="mr-15px"
                          >
                            <img
                              class="mx-auto"
                              src="/images/svg/projects/file_download.svg"
                              :alt="`download ${file.filename}`"
                            />
                          </a>
                          <a
                            class="mobile-disabled print-button"
                            :class="{ disabled: !printingEnabled }"
                            href="#null"
                            v-on:click="print(file.urls.print)"
                          >
                            <img
                              class="mx-auto"
                              src="/images/svg/projects/file_print_small.svg"
                              :alt="`print ${file.filename}`"
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="flex">
                      <a
                        :href="urls.download"
                        :download="
                          `${formatISODate(project.due_date, 'MMdd')}_${
                            project.name
                          }.pdf`
                        "
                        class="mr-20px"
                      >
                        <img
                          class="mx-auto"
                          src="/images/svg/projects/file_pdf_download.svg"
                          :alt="`download all pdfs`"
                        />
                        <span class="text-3xs">{{ "全てダウンロード" }}</span>
                      </a>
                      <a
                        class="mobile-disable print-button"
                        :class="{ disabled: !printingEnabled }"
                        href="#null"
                        v-on:click="print(urls.print)"
                      >
                        <img
                          src="/images/svg/projects/file_print.svg"
                          :alt="`print all pdfs`"
                        />
                        <span class="text-3xs">{{ "全て印刷" }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field md:flex-row flex-col">
              <label class="mb-15px">{{ "変更履歴" }}</label>
              <div>
                <div
                  class="flex flex-wrap mb-15px"
                  v-for="update in project.project_updates"
                  v-bind:key="update.id"
                >
                  <div class="pr-10px mb-5px">
                    {{
                      formatISODate(update.created_at, "yyyy-MM-dd HH:mm:ss")
                    }}
                  </div>
                  <div>
                    {{ updateCreatedByDepartment(update.created_by) }}
                  </div>
                  <div class="pl-10px">
                    {{ updateCreatedByName(update.created_by) }}
                  </div>
                  <div
                    class="w-full p-5px bg-grey00"
                    style="border-radius: 10px"
                  >
                    {{ update.comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container__footer">
        <div
          class="container__footer__block container__footer__block--left"
        ></div>
        <div class="container__footer__block"></div>
        <div
          class="container__footer__block container__footer__block--right"
        ></div>
      </div>
    </div>
    <projects-modal-discard
      :url="urls.delete"
      v-on:close="closeDiscardModal"
      :class="{ hidden: discardModalHidden }"
    ></projects-modal-discard>
  </div>
</template>

<style scoped>
label {
  border-style: solid;
  border-color: #323232;
}
.project-comment, .info-block {
  white-space: pre-line;
}
</style>

<script>
import print from "print-js";

export default {
  props: {
    urls: Object,
    project: Object,
    user: Object
  },
  data() {
    return {
      discardModalHidden: true,
      printingEnabled: false
    };
  },
  computed: {
    isUserAffiliate: function() {
      if (this.user.affiliate) {
        return true;
      }
      return false;
    },
    managerName() {
      if (this.project.manager) {
        return this.project.manager.name;
      }
      return "";
    },
    customerName() {
      if (this.project.customer) {
        return this.project.customer.name;
      }
      return "";
    },
    contactName() {
      if (this.project.contact) {
        return this.project.contact.name;
      }
      return "";
    },
    outputManagerName() {
      if (this.project.output_manager) {
        return this.project.output_manager.name;
      }
      return "";
    },
    technicalManagerName() {
      if (this.project.technical_manager) {
        return this.project.technical_manager.name;
      }
      return "";
    },
    processingManagerName() {
      if (this.project.processing_manager) {
        return this.project.processing_manager.name;
      }
      return "";
    }
  },
  methods: {
    formatDate(dateString) {
      var date = new Date(dateString);
      return date
        .toJSON()
        .slice(0, 19)
        .replace("T", " ")
        .replaceAll(":", "_");
    },
    buildBillUrl(bill) {
      if (bill) {
        return this.urls.billShow.replace(/__BILL_ID__/, bill.id);
      }
      return "";
    },
    updateCreatedByDepartment(user) {
      if (user) {
        return user.department;
      }
      return "";
    },
    updateCreatedByName(user) {
      if (user) {
        return user.name;
      }
      return "";
    },
    openDiscardModal() {
      this.discardModalHidden = false;
    },
    closeDiscardModal() {
      this.discardModalHidden = true;
    },
    print(url) {
      if (this.printingEnabled) {
        printJS(url);
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.printingEnabled = true;
    });
  }
};
</script>
